import { render, staticRenderFns } from "./DrupalRadios.vue?vue&type=template&id=b668fa62&scoped=true"
import script from "./DrupalRadios.vue?vue&type=script&lang=js"
export * from "./DrupalRadios.vue?vue&type=script&lang=js"
import style0 from "./DrupalRadios.vue?vue&type=style&index=0&id=b668fa62&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../gestion-tache-vuejs-v2/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b668fa62",
  null
  
)

export default component.exports