var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.classCss,attrs:{"type-field-render":"DrupalRadios","type-field-drupal":_vm.field.type}},[_c('div',{staticClass:"field-item-value js-form-type-radio",attrs:{"format_val":_vm.format_val}},[(
        _vm.field.definition_settings.target_type &&
        _vm.field.definition_settings.target_type == 'taxonomy_term'
      )?_c('OptionsTaxonomy',{attrs:{"field":_vm.field,"model":_vm.model,"namespace-store":_vm.namespaceStore,"fullname":_vm.fullname},on:{"setValue":_vm.setValue}}):(
        _vm.field.definition_settings.allowed_values &&
        Object.keys(_vm.field.definition_settings.allowed_values).length > 0
      )?_c('OptionsAllowedValues',{attrs:{"field":_vm.field,"model":_vm.model,"namespace-store":_vm.namespaceStore,"fullname":_vm.fullname},on:{"setValue":_vm.setValue}}):(
        _vm.field.definition_settings.target_type &&
        _vm.field.definition_settings.target_type != ''
      )?_c('OptionsEntities',{attrs:{"field":_vm.field,"model":_vm.model,"namespace-store":_vm.namespaceStore},on:{"setValue":_vm.setValue}}):_c('ValidationProvider',{attrs:{"name":_vm.fullname,"rules":_vm.getRules()},scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('b-form-group',{class:_vm.size ? 'size-' + _vm.size : '',attrs:{"label":_vm.field.label,"name":_vm.fullname}},[_c('div',{staticClass:"fieldset-wrapper"},[(_vm.field.settings && _vm.field.settings.list_options)?_c('div',{staticClass:"radio"},_vm._l((_vm.field.settings.list_options),function(option,o){return _c('b-form-radio',{key:o,staticClass:"form-check",attrs:{"name":_vm.fullname,"value":option.value,"size":_vm.size,"state":_vm.getValidationState(v)},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',[(option.image_url)?_c('b-img',{attrs:{"thumbnail":"","fluid":"","src":option.image_url,"alt":"Image 1"}}):_vm._e(),(!option.image_url)?_c('svgLoader'):_vm._e()],1)]),_c('div',{staticClass:"mt-5"},[_vm._v(_vm._s(option.label))]),(
                  option.description.value && option.description.value !== ''
                )?_c('div',{staticClass:"mt-5 text-hover",domProps:{"innerHTML":_vm._s(option.description.value)}}):_vm._e()],1)}),1):_vm._e(),(v.errors)?_c('div',{staticClass:"text-danger my-2"},_vm._l((v.errors),function(error,ii){return _c('small',{key:ii,staticClass:"d-block"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()])])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }