var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.classCss,attrs:{"type-field-render":"MultiSelect","type-field-drupal":_vm.field.type}},[(
      _vm.field.definition_settings &&
      _vm.field.definition_settings.target_type == 'taxonomy_term'
    )?_c('MultiSelectTaxo',{attrs:{"field":_vm.field,"model":_vm.model,"namespace-store":_vm.namespaceStore,"parent-name":_vm.parentName,"override-config":_vm.overrideConfig}}):(
      _vm.field.definition_settings &&
      _vm.field.definition_settings.target_type != 'taxonomy_term'
    )?_c('MultiSelectEntities',{attrs:{"field":_vm.field,"model":_vm.model,"namespace-store":_vm.namespaceStore,"parent-name":_vm.parentName,"override-config":_vm.overrideConfig}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }